import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import { Homepage } from './pages/Homepage';


import { LoginForm } from './pages/LoginForm';

import { Layout } from './components/Layout'

import { AuthProvider } from './hoc/AuthProvider'

function App() {
  const [token, setToken] = useState();
  if(!token) {
    return <LoginForm setToken={setToken} />
  }
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index element={<Homepage />} />

        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
