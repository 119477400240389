import axios from "axios";

const token = process.env.REACT_APP_TOKEN_BACKEND

export const ReqToAds = async (lines, urls) => {
  return axios.post(
    process.env.REACT_APP_URL_BACKEND+"api/v1/validator/check_lines",
    {
      lines: lines,
      urls: urls,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};
