import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Grommet, Box, Button, Form, FormField, TextInput, Notification } from "grommet";

async function loginUser(credentials) {
  return await axios
    .post(
      process.env.REACT_APP_URL_BACKEND + "api/v1/get_token",
      {
        email: credentials["email"],
        password: credentials["password"],
        site_name: credentials["site_name"],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + process.env.REACT_APP_TOKEN_BACKEND,
        },
      }
    )
    .then((data) => {
      return data.data.data.token;
    })
    .catch((error) => console.log(error));
}

const LoginForm = ({ setToken }) => {
  const customTheme = {
    global: {
      colors: {
        brand: "#299e23",
      },
    },
  };
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [visible, setVisible] = React.useState();

  const handleemailChange = (e) => {
    setemail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await loginUser({
      email,
      password,
      site_name: "front",
    });
    setToken(token);

    if (token) {
      setMessage("Login successful!");
      setVisible(true);
    } else {
      setMessage("Invalid email or password");
      setVisible(true);
    }
  };

  return (
    <Grommet theme={customTheme}>
      <Box align="center" pad="medium">
        <Form onSubmit={handleSubmit}>
          <FormField label="Email">
            <TextInput
              type="text"
              id="email"
              value={email}
              onChange={handleemailChange}
            />
          </FormField>
          <FormField label="Password">
            <TextInput
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </FormField>
          <Box direction="row" justify="between" margin={{ top: "medium" }}>
            <Button type="submit" primary label="Log In" />
          </Box>
        </Form>
        {visible && (
          <Notification
            toast
            title="Login"
            message={message}
            onClose={() => setVisible(false)}
          />
        )}
      </Box>
    </Grommet>
  );
};

LoginForm.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export { LoginForm };
