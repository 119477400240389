import { useState, CSSProperties } from "react";
import { ReqToAds } from "./ReqToAds";
import {
  DataTable,
  Grommet,
  Heading,
  Box,
  Button,
  TextInput,
  Text,
  CheckBox,
  Tabs,
  Tab,
  TextArea,
} from "grommet";
import { SubtractCircle, AddCircle, CloudDownload } from "grommet-icons";
import { CSVLink } from "react-csv";
import PropagateLoader from "react-spinners/PropagateLoader";


const override: CSSProperties = {
  display: "block",
  margin: "-50px 400px 80px 400px",
  borderColor: "#299e23",
};

function TableComponent({ columns, data, handleSort, checked }) {
  const [valueCsv, setValueCsv] = useState([]);
  const exportCSV = (e) => {
    let dataCsv = [];
    data.forEach((item) => {
      if (checked.indexOf(item.num) !== -1) {
        dataCsv.push(item);
      }
    });
    // console.log("dataCsv =", dataCsv);
    setValueCsv(dataCsv);
  };

  if (data.length > 0) {
    return (
      <Box pad="medium" className="boxDataTable boxCustom">
        <Box fill="horizontal" pad="medium">
          <CSVLink onClick={exportCSV} data={valueCsv} filename='adstxt_checked'>
            <CloudDownload size="20px" /> Download CSV
          </CSVLink>
          <Heading level="3"> Result DataTable</Heading>
          <DataTable
            columns={columns}
            data={data}
            onSort={handleSort}
            step={10}
            paginate={true}
          />
        </Box>
      </Box>
    );
  }
}

function FormCheckLine() {
  const customTheme = {
    global: {
      colors: {
        brand: "#299e23",
      },
    },
  };

  let [loading, setLoading] = useState(false);

  const [valueTexarea, setValueTexarea] = useState("");
  const [valueUrlTexarea, setValueUrlTexarea] = useState("");

  const [formLines, setFormLines] = useState([{ line: "" }]);
  const handleLineChange = (event, index) => {
    let data = [...formLines];
    data[index].line = event.target.value;
	const caretStart = event.target.selectionStart;
	const caretEnd = event.target.selectionEnd;
	setFormLines(data);
	setTimeout(() => {
		event.target.setSelectionRange(caretStart, caretEnd)
	}, 10)
  };
  const addLines = () => {
    let object = {
      line: "",
    };
    setFormLines([...formLines, object]);
  };
  const removeLines = (index) => {
    let data = [...formLines];
    data.splice(index, 1);
    setFormLines(data);
  };

  const [formFiles, setFormFiles] = useState([{ file: "" }]);
  const handleFileChange = (event, index) => {
    let data = [...formFiles];
    data[index].file = event.target.value;
	const caretStart = event.target.selectionStart;
	const caretEnd = event.target.selectionEnd;
	setFormFiles(data);
	setTimeout(() => {
		event.target.setSelectionRange(caretStart, caretEnd)
	}, 10)
  };
  const addFiles = () => {
    let object = {
      file: "",
    };
    setFormFiles([...formFiles, object]);
  };
  const removeFiles = (index) => {
    let data = [...formFiles];
    data.splice(index, 1);
    setFormFiles(data);
  };

  const [data, setData] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (property) => {
    const sorted = [...data].sort((a, b) => {
      if (a[property] < b[property]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });

    setData(sorted);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const [checked, setChecked] = useState([]);

  const onCheckAll = (event) =>
    setChecked(event.target.checked ? data.map((datum) => datum.num) : []);

  const columns = [
    {
      property: "checkbox",
      render: ({ num }) => (
        <CheckBox
          key={num}
          checked={checked.indexOf(num) !== -1}
          onChange={(e) => onCheck(e, num)}
          aria-label="row checkbox"
        />
      ),
      header: (
        <CheckBox
          checked={checked.length === data.length}
          indeterminate={checked.length > 0 && checked.length < data.length}
          onChange={onCheckAll}
          aria-label="header checkbox"
        />
      ),
      sortable: false,
    },
    //     {
    //       property: "num",
    //       header: <Text>Num</Text>,
    //       search: true,
    //       sortable: true,
    //       pin: true,
    //     },
    {
      property: "line",
      header: <Text>Line</Text>,
      search: true,
      sortable: true,
      pin: true,
    },
    {
      property: "url",
      header: <Text>ads.txt URL</Text>,
      search: true,
      sortable: true,
    },
    {
      property: "status",
      header: <Text>Status</Text>,
      search: true,
      sortable: true,
    },
  ].map((col) => ({ ...col }));

  const controlledColumns = columns.map((col) => ({ ...col }));
  delete controlledColumns[0].footer;
  delete controlledColumns[1].footer;
  delete controlledColumns[2].footer;
  delete controlledColumns[2].aggregate;

  const onCheck = (event, value) => {
    if (event.target.checked) {
      setChecked([...checked, value]);
    } else {
      setChecked(checked.filter((item) => item !== value));
    }
  };

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
	setData([]);

    const isTextarea = document
      .getElementById("tabTextArea")
      .getAttribute("aria-selected");
    let sendLines = [];
    if (isTextarea === "true") {
      const lines = valueTexarea.split("\n");
      lines.forEach((line) => {
        if (line.length > 0) {
          sendLines.push({ line: line });
        }
      });
    } else {
      sendLines = formLines;
    }
    const isUrlTextarea = document
      .getElementById("tabUrlTextArea")
      .getAttribute("aria-selected");
    let sendUrlLines = [];
    if (isUrlTextarea === "true") {
      const files = valueUrlTexarea.split("\n");
      files.forEach((file) => {
        if (file.length > 0) {
          sendUrlLines.push({ file: file });
        }
      });
    } else {
      sendUrlLines = formFiles;
    }
    // console.log(JSON.stringify({ lines: sendLines, urls: sendUrlLines }));

    if (formLines.length > 0 && formFiles.length > 0) {
      ReqToAds(sendLines, sendUrlLines)
        .then((response) => {
          // console.log("response ==", response.data);
          setData(response.data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
		  document.getElementById('scroller').scrollTo({top: 0, behavior: 'smooth'});
        });
    }
  };

  return (
    <Grommet theme={customTheme}>
      <form onSubmit={submit}>
        <Box pad="medium" className="boxCustom">
          <Heading textAlign="start" level="3" margin="1px">
            Lines
          </Heading>
          <Tabs>
            <Tab title="enter line as Line" id="tabLline">
              <AddCircle size="20px" onClick={addLines} />
              {formLines.map((form, index) => {
                return (
                  <div key={index}>
                    <Box direction="row" gap="medium">
                      <TextInput
                        className="formInputClass"
                        line="line"
                        placeholder="Line from ads.txt file"
                        value={form.line}
                        onChange={(event) => handleLineChange(event, index)}
                      />
                      <SubtractCircle
                        size="20px"
                        onClick={() => removeLines(index)}
                      />
                    </Box>
                  </div>
                );
              })}
            </Tab>
            <Tab title="enter line as TextArea" id="tabTextArea">
              <TextArea
                placeholder="type here"
                className="formTextareaClass"
                value={valueTexarea}
                onChange={(event) => {
					const caretStart = event.target.selectionStart;
					const caretEnd = event.target.selectionEnd;
					setValueTexarea(event.target.value)
					setTimeout(() => {
						event.target.setSelectionRange(caretStart, caretEnd)
					}, 10)
				}}
              />
            </Tab>
          </Tabs>
        </Box>

        <Box pad="medium" className="boxUrl boxCustom">
          <Heading textAlign="start" level="3" margin="10px">
            URLs
          </Heading>
          <Tabs>
            <Tab title="enter urls as Line" id="tabUrlLine">
              <AddCircle size="20px" onClick={addFiles} />
              {formFiles.map((form, index) => {
                return (
                  <div key={index}>
                    <Box direction="row" gap="medium">
                      <TextInput
                        className="formInputClass"
                        file="file"
                        placeholder="URL ads.txt file"
                        value={form.file}
                        onChange={(event) => handleFileChange(event, index)}
                      />
                      <SubtractCircle
                        size="20px"
                        onClick={() => removeFiles(index)}
                      />
                    </Box>
                  </div>
                );
              })}
            </Tab>
            <Tab title="enter urls as TextArea" id="tabUrlTextArea">
              <TextArea
                placeholder="type here"
                className="formTextareaClass"
                value={valueUrlTexarea}
                onChange={(event) => {
					const caretStart = event.target.selectionStart;
					const caretEnd = event.target.selectionEnd;
					setValueUrlTexarea(event.target.value)
					setTimeout(() => {
						event.target.setSelectionRange(caretStart, caretEnd)
					}, 10)
				}}
              />
            </Tab>
          </Tabs>
        </Box>
      </form>

      <Button onClick={submit} primary label="Check" margin="30px 0"/>

      <PropagateLoader
	  	color="#299e23"
		size={10}
        loading={loading}
        cssOverride={override}
        aria-label="Loading Spinner"
        data-testid="loader"
      />

      <TableComponent
        columns={columns}
        data={data}
        handleSort={handleSort}
        checked={checked}
      />
	  <div id="scroller"></div>
    </Grommet>
  );
}

export { FormCheckLine };
