import { FormCheckLine } from '../components/FormCheckLine';


const Homepage = () => {
    return (
        <div>
            <h1>Check line in ads.txt file</h1>
			<FormCheckLine />
        </div>
    )
}

export {Homepage}
